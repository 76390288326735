@mixin centerer {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin media($width) {
  @media (min-width: $width) {
    @content;
  }
}
