.footer {
  position: fixed;
  z-index: 500;
  bottom: 0;
  left: $collapsed-menu-width;
  width: calc(100% - #{$collapsed-menu-width});
  background-color: #FFF;
  border-top: 1px solid #DDD;
  padding: $column-v-padding ($column-h-padding * 2);
  font-size: $font-size-small;
  text-align: center;
  color: #888;

  .logo {
    margin-bottom: ($column-v-padding/2);
  }

  @include media($screen-md-min) {
    text-align: right;
    left: $menu-width;
    width: calc(100% - #{$menu-width});
  }
}