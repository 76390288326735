.f-wizard {
  padding: 0;
  background: #fff;
  border-radius: 4px;

  .f-wizard-panel {
    padding: $panel-padding $panel-padding ($panel-padding*2) $panel-padding;

    fieldset {
      margin: 0 -(#{$panel-padding});
      padding: 0 $panel-padding $column-v-padding $panel-padding;
    }
  }
  h2:first-child {
    margin: 0 0 $panel-padding 0;
  }
}
.page .f-wizard .message {
  margin: 0 -#{$panel-padding} $panel-padding -#{$panel-padding};
  box-shadow: none;
}
.f-wizard-steps {
  overflow: hidden;
  position: relative;
  padding: 20px 0;
  margin: 0;
  text-align: center;
  background-color: $gray-lighter;
}
.f-wizard-progress {
  position: absolute;
  top: 44px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #CCC;
}
.f-wizard-progress-line {
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  background: $chc-yellow;
}
.f-wizard-step {
  position: relative;
  float: left;
  width: 16.666666%;
  padding: 0 5px;
}
.f-wizard-step-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-top: 4px;
  background: #CCC;
  color: $gray;
  border-radius: 50%;
  line-height: 40px;
  vertical-align: middle;

  .wfmi, .fa {
    font-size: 22px;
    line-height: 40px;
  }
}
.f-wizard-step.activated .f-wizard-step-icon {
  background: #FFF;
  border: 1px solid $chc-yellow;
  line-height: 38px;
}
.f-wizard-step.active .f-wizard-step-icon {
  border: 1px solid $chc-yellow;
  width: 48px;
  height: 48px;
  margin-top: 0;
  background: desaturate(lighten($chc-yellow, 30%), 30%);
  color: $chc-charcoal;
  font-size: 22px;
  line-height: 48px;
}
.f-wizard-step p {
  color: #ccc;
}
.f-wizard-step.activated p {
  color: $chc-yellow;
}
.f-wizard-step.active p {
  color: $chc-yellow;
}
.f-wizard .f-wizard-panel {
  display: none;
  text-align: left;
}
.f-wizard-buttons {
  text-align: center;
  @include media($screen-md-min) {
    text-align: right;
  }
}
.f-wizard .input-error {
  border-color: $chc-yellow;
}