#other-type-group, #other-pharmacy-type-group, #other-business-designation-group, #contact-group-340b, #offenses-group {
  display: none;
}
.site-disclosure {
  border-bottom: 1px solid $hr-border;
  margin-bottom: $column-v-padding;
}
.site-disclosure:last-of-type {
  border-bottom: 0;
  margin-bottom: 0;
}