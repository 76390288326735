.control-label {
  display: inline-block;
  font-weight: bold;
  margin-top: $column-v-padding;
}
.inline-control-label {
  display: inline-block;
  margin-right: $column-h-padding;
}
.instruction {
  color: $message-info-text-color;
  font-weight: normal;
}
.help-block {
  font-style: oblique;
  font-size: $font-size-small;
}
.add-item-btn {
  margin-bottom: $column-v-padding;
}
.btn-chc {
  @include button-variant(#FFF, $chc-yellow, darken($chc-yellow, 2%));
}
textarea.form-control.textarea {
  height: 7em;
}
form .row {
  margin-left: -(#{$column-h-padding});
  margin-right: -(#{$column-h-padding});
}
fieldset > hr {
  margin: $column-v-padding -(#{$panel-padding});
}
legend {
  border: none;
  margin: 0 -(#{$panel-padding}) $legend-bottom-margin -(#{$panel-padding});
  padding: 0 $panel-padding;
  background-color: $gray-lighter;
  width: auto;
}
fieldset fieldset legend {
  background-color: lighten($chc-yellow, 35%);
}
fieldset fieldset:first-of-type {
  margin-top: -(#{$legend-bottom-margin}) !important;
}
.form-control {
  box-shadow: none;

  &:focus {
    box-shadow: none;
    background-color: $field-focus-bg-color;
    border-color: $field-focus-border-color;
  }

  &:focus::selection {
    background-color: $field-focus-text-color;
    color: #FFF;
  }
}
.inline-group {
  label, input {
    display: inline-block;
    margin-right: $column-h-padding;
  }
  label.radio, label.checkbox {
    input {
      position: relative;
      margin-left: 0;
      margin-right: 0;
    }
  }
}
.reveal-other-container {
  margin-bottom: $column-v-padding;
}
.reveal-other-details-hidden {
  display: none;
}
.form-control.calendar[readonly] {
  background-color: #FFF;
}