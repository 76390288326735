.message {
  background-color: $message-info-bg-color;
  border: 1px solid $message-info-border-color;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);
  color: $message-info-text-color;
  padding: $column-h-padding;
  text-align: center;

  a {
    color: $message-info-text-color;
    text-decoration: underline;

    &:hover {
      color: $chc-black;
    }
  }
}
.success {
  @extend .message;
  background-color: #E5F2E5;
  border: 1px solid lighten(#008000, 10%);
  color: #008000;

  a {
    color: #008000;
    text-decoration: underline;
  }
}
.warn {
  @extend .message;
  background-color: #EAE6D8;
  border: 1px solid lighten(#594308, 10%);
  color: #594308;

  a {
    color: #594308;
    text-decoration: underline;
  }
}
.error {
  @extend .message;
  background-color: #E5CECF;
  border: 1px solid lighten(#450209, 10%);
  color: #450209;

  a {
    color: #450209;
    text-decoration: underline;
  }
}

.page .message, .page .success,
.page .warn, .page .error {
  margin: 0 ($column-h-padding * 2) 0 ($column-h-padding * 2);
  border: none;
}