@import "https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,700,700i";

// Fonts
$body-font: 'Open Sans', sans-serif;

// Colors
$chc-charcoal: #231F20;
$chc-black: #000000;
$chc-yellow: #E9C200;
$link-font-color: #7F909A;
$action-menu-font-color: #DDD;
$menu-font-color: #CCC;
$message-info-bg-color: #EFF7FC;
$message-info-border-color: #66AFE9;
$message-info-text-color: darken($message-info-border-color, 20%);
$field-focus-bg-color: lighten($message-info-bg-color, 2%);
$field-focus-border-color: $message-info-border-color;
$field-focus-text-color: $message-info-text-color;

// Padding
$column-h-padding: ($grid-gutter-width / 2);
$column-v-padding: floor(($grid-gutter-width / 3));
$content-h-padding: ($grid-gutter-width * 3);

// Layout
$collapsed-menu-width: 50px;
$menu-width: 260px;
$panel-padding: ($column-h-padding * 2);

// Forms
$legend-bottom-margin: ($panel-padding/2);