// Webfont-Medical-Icons Override
@font-face{
  font-family: webfont-medical-icons-override;
  src: url(./fonts/webfont-medical-icons.eot);
  src: url(./fonts/webfont-medical-icons.eot?#iefix) format('embedded-opentype'), url(./fonts/webfont-medical-icons.ttf) format('truetype'), url(./fonts/webfont-medical-icons.woff) format('woff'),url(./fonts/webfont-medical-icons.svg#webfont-medical-icons) format('svg');
  font-weight: 400;
  font-style: normal;
}
.wfmi {
  font: normal normal normal 14px/1 webfont-medical-icons-override;
}

* {
  box-sizing: border-box;
}
html {
  height: 100%;
  font-size: $font-size-base;
  background-color: #FFF;
}
body {
  position: relative;
  min-height: 100%;
  font-family: $body-font;
}
::selection {
  background-color: $chc-yellow;
  color: #FFF;
}
a {
  color: $link-font-color;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: darken($link-font-color, 20%);
  }
}
h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
  text-transform: none;
}
h3 a {
  color: darken(#91AA9D, 10%);
}
.auto {
  width: 100%;
  height: auto;
}
.auto-v {
  width: 100%;
  height: auto;

  @include media($screen-md-min) {
    width: auto;
    height: 100%;
  }
}
.no-margin {
  margin: 0;
}
.no-padding {
  padding: 0;
}
.block {
  display: block;
}
.row {
  margin: 0;
}
.wrapper {
  height: 100vh;
  @include clearfix;
}
.important {
  color: darkred;
  font-weight: bold;
}

// Layout
.menu, .page {
  height: 100%;
  max-height: 100%;
  overflow: auto;
}
.menu {
  float: left;
  z-index: 1001;
  background-color: $chc-charcoal;
  width: $collapsed-menu-width;

  @include media($screen-md-min) {
    width: $menu-width;
  }

  .brand {
    display: none;
    height: 75px;
    vertical-align: middle;
    margin-bottom: ($column-h-padding * 2);

    h1 {
      margin: 0;
      color: $chc-yellow;
      text-align: center;
      line-height: 75px;
      font-size: $font-size-h3;
    }

    @include media($screen-md-min) {
      display: block;
    }
  }

  ul {
    margin-top: $column-v-padding;

    @include media($screen-md-min) {
      margin-top: 0;
    }

    h2 {
      padding: 0;
      border-bottom: 1px solid #333;
      margin: $column-v-padding 0;

      @include media($screen-md-min) {
        font-size: $font-size-large;
        border-bottom: none;
        padding: 0 ($column-h-padding*2);
        color: $chc-yellow;
        text-shadow: -1px -1px 1px #333;
      }

      span {
        display: none;

        @include media($screen-md-min) {
          display: inline;
        }
      }
    }
  }

  a {
    color: #DDD;
    display: block;
    padding: ($column-v-padding/2) $column-h-padding;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    font-size: $font-size-small;

    &:hover {
      color: #FFF;
    }

    i {
      font-size: 1.5em;
      padding: 0;

      @include media($screen-md-min) {
        padding-right: $column-h-padding;
      }
    }

    @include media($screen-md-min) {
      padding: ($column-v-padding/2) ($column-h-padding*2);
      text-align: left;
    }

    @include media($screen-lg-min) {
      padding: $column-v-padding ($column-h-padding*2);
    }

    span {
      display: none;

      @include media($screen-md-min) {
        display: inline;
      }
    }

    &.active {
      background-color: $chc-yellow;
      color: $chc-black;
      cursor: default;

      &:hover {
        background-color: $chc-yellow;
      }
    }

    &:hover {
      background-color: #555;
    }
  }

  .powered-by {
    margin-top: ($column-v-padding * 4);
    color: $chc-yellow;
    text-align: center;
    font-size: $font-size-small;

    img {
      height: 20px;
      width: auto;

      @include media($screen-md-min) {
        display: block;
        width: 50%;
        height: auto;
        margin: ($column-v-padding/2) auto 0  auto;
      }
    }
  }
}
.page {
  float: right;
  position: relative;
  background-color: #EEE;
  width: calc(100% - #{$collapsed-menu-width});
  min-height: 100%;

  @include media($screen-md-min) {
    width: calc(100% - #{$menu-width});
  }
}
.title-bar {
  padding: 0 $column-h-padding;
  margin-bottom: ($column-h-padding * 2);

  @include media($screen-md-min) {
    margin-bottom: ($column-h-padding * 2);
  }

  h1, .actions {
    color: $chc-charcoal;
  }

  h1 {
    margin: ($column-v-padding*2) 0 $column-v-padding 0;
    line-height: auto;
    vertical-align: middle;
    font-size: $font-size-h3;

    @include media($screen-md-min) {
      margin: 0;
      line-height: 75px;
    }
  }

  .title {
    text-align: center;

    @include media($screen-md-min) {
      text-align: left;
      height: 75px;
    }
  }

  .actions {
    text-align: center;

    @include media($screen-md-min) {
      text-align: right;
      height: 75px;
    }

    i {
      line-height: 35px;
      vertical-align: middle;

      @include media($screen-md-min) {
        line-height: 75px;
      }
    }

    input {
      margin: 0 0 0 $column-v-padding;
      background-color: transparent;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid #888;
      width: 50%;
      height: 35px;

      @include media($screen-md-min) {
        margin: 20px 0 20px $column-v-padding;
      }
    }

    .btn-save-state {
      margin: $column-v-padding 0 0 $column-v-padding;
      line-height: 35px;
      height: 35px;
      padding: 0 $padding-large-horizontal;
      border: none;
      background-color: $gray-light;
      color: $gray-lighter;

      &:hover {
        background-color: $gray;
        color: #FFF;
      }

      &:active, &:focus {
        background-color: $chc-yellow;
        color: $chc-charcoal;
      }

      i {
        line-height: 35px;
      }

      @include media($screen-md-min) {
        margin: 20px 0 20px $column-v-padding;
      }
    }
  }
}

.content {
  padding-bottom: 100px;
  .breadcrumb {
    background-color: transparent;
    padding: 0 ($column-h-padding * 2);
  }
}