.panel-row {
  padding: 0 $column-h-padding;
}

.panel {
  background-color: #FFF;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08), 0 0 0 1px rgba(63, 63, 68, 0.1);
  border: none;
  min-height: 100px;
  padding: $panel-padding;

  .no-results {
    text-align: center;
    font-style: oblique;
    margin: 0 0 ($panel-padding * 2) 0;
  }

  .strip-padding {
    margin: -#{$panel-padding} -#{$panel-padding};

    .panel-head {
      padding: $panel-padding;
    }

    .table-responsive {
      margin-bottom: 0;
    }

    .no-results {
      padding: 0 $panel-padding;
      font-size: 1.25em;
      color: #777;
    }

    table {
      margin-bottom: 0;
    }

    table tr > td:first-child, table tr > th:first-child {
      padding-left: $panel-padding;
    }
    table tr > td:last-child, table tr > th:last-child {
      padding-right: $panel-padding;
    }
  }
}